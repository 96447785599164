import React from "react";
import Console from "./common/Console";
import {
  DxcFooter,
  ThemeContext,
  DxcApplicationLayout,
} from "@dxc-technology/halstack-react";
import linkedinLogo from "./images/linkedin.svg";
import twitterLogo from "./images/twitter.svg";
import facebookLogo from "./images/facebook.svg";
import { Header, useAssureTheme } from "@diaas/assure-platform-react-tools";
import styled from "styled-components";

function App() {
  const { isThemeLoaded, theme } = useAssureTheme();
  const year = new Date().getFullYear();

  document.getElementsByTagName("link")[0].href = theme.favicon;
  document.title = theme.tabTitle
    ? `${theme.tabTitle} Platform Console`
    : "Platform Console";
  const companyName = theme.companyName;

  return (
    (isThemeLoaded === "loading" && <LoadingBackground />) ||
    ((isThemeLoaded === "loadedSuccess" || isThemeLoaded === "loadedError") && (
      <ThemeContext.Provider value={theme.components}>
        <DxcApplicationLayout>
          <DxcApplicationLayout.Header>
            <Header />
          </DxcApplicationLayout.Header>
          <DxcApplicationLayout.Main>
            <Console companyName={companyName} />
          </DxcApplicationLayout.Main>
          <DxcApplicationLayout.Footer>
            <DxcFooter
              copyright={`© DXC Technology ${year}. All rights reserved.`}
              bottomLinks={[
                {
                  href: "https://www.linkedin.com/company/dxctechnology",
                  text: "Linkedin",
                },
                {
                  href: "https://twitter.com/dxctechnology",
                  text: "Twitter",
                },
                {
                  href: "https://www.facebook.com/DXCTechnology/",
                  text: "Facebook",
                },
              ]}
              socialLinks={[
                {
                  href: "https://www.linkedin.com/company/dxctechnology",
                  logoSrc: linkedinLogo,
                },
                {
                  href: "https://twitter.com/dxctechnology",
                  logoSrc: twitterLogo,
                },
                {
                  href: "https://www.facebook.com/DXCTechnology/",
                  logoSrc: facebookLogo,
                },
              ]}
            ></DxcFooter>
          </DxcApplicationLayout.Footer>
        </DxcApplicationLayout>
      </ThemeContext.Provider>
    ))
  );
}

const LoadingBackground = styled.div`
  background-color: #000000cc;
  width: 100%;
  height: 100%;
  position: fixed;
`;

export default App;
